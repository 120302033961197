import React from "react";
import "./footer.scss";

import { Link } from "react-router-dom";

import facebook from "../../assets/img/icons/facebook.png";
import youtube from "../../assets/img/icons/youtube.png";
//import googleplus from '../../assets/img/icons/googleplus.png';
import twitter from "../../assets/img/icons/X.png";
import instagram from "../../assets/img/icons/instagram.png";
import ibama from "../../assets/img/icons/selo_ibama.png";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3>
              <strong>Siga a Chevrolet</strong>
            </h3>
            <div className="footerSocialMedia">
              <a
                href="https://pt-br.facebook.com/chevroletbrasil/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebook} alt="Chevrolet" />
              </a>
              <a
                href="https://twitter.com/chevroletbrasil"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={twitter} alt="Chevrolet" />
              </a>
              <a
                href="https://www.youtube.com/chevroletbrasil"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={youtube} alt="Chevrolet" />
              </a>
              <a
                href="https://www.instagram.com/chevroletbr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instagram} alt="Chevrolet" />
              </a>
            </div>
          </div>

          <div className="col-12 footerContent">
            <img src={ibama} alt="ibama" />

            <p>
              Faça revisões em seu veículo regularmente. Estes veículos estão em conformidade com o Programa de Controle da Poluição do Ar por Veículos Automotores - PROCONVE. 
              Paz no trânsito começa por você. 
              Campanha Clínica de Serviços: Realize “Checkup gratuito” ou Serviço em nossa Rede de Concessionárias Chevrolet durante o período de 13.06.2024 a 23.06.2024 e concorra a um carro 0km, conforme os termos da campanha. 
              Consulte os regulamentos completos com todas as condições de participação e elegibilidade em &nbsp;

              <a
                className="link-chev"
                href="https://www.clinicadeservicoschevrolet.com.br"
                target="_blank"
                rel="noopener noreferrer"

              >
                www.clinicadeservicoschevrolet.com.br
              </a>
              . <br></br>CAMPANHA AUTORIZADA ATRAVÉS DO CERTIFICADO DE AUTORIZAÇÃO SPA/ME Nº 04.034683/2024. Imagens ilustrativas.

            </p>
          </div>

          <div className="col-12">
            <ul>
              <li>Copyright Chevrolet</li>
              <li>
                <a
                  href="https://www.chevrolet.com/worldwide-websites"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Chevrolet Worldwide
                </a>
              </li>
              <li>
                <Link to="/politica-de-privacidade">Informações legais</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
