import React from "react";
import pdfRegulamento from "../Texto/Regulamento.pdf";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";

const RegulamentoConteundo = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <>
      <div style={{height:'700px', width:'100%'}} >
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
          <Viewer
            fileUrl={pdfRegulamento}
            wrap={false}
            plugins={[defaultLayoutPluginInstance]}
          ></Viewer>
        </Worker>
      </div>
    </>
  );
};

export default RegulamentoConteundo;
