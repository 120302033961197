import React, { Fragment, useEffect, useState } from "react";
import "./menu.scss";

import { Link } from "react-router-dom";
import Storage from "../../services/storage";
import { slide as Menu } from "react-burger-menu";
import logoCompany from "../../assets/img/icons/chevrolet-black.svg";

const MenuNav = () => {
  const [userToken, setUserToken] = useState(false);
  const [siglaOla, setSiglaOla] = useState("");
  const [fechaMenu, setFechaMenu] = useState(false);


  function sairLogado() {
    Storage.del("DEVP_USER_INFOS_ROLETA")
    Storage.del("DEVP_USER_INFOS")
    Storage.del("captcha")
    setUserToken(false);
    window.location.reload();
  }

  useEffect(() => {
    if (Storage.getUser() !== null) {
      setUserToken(true);
      const arrNome = Storage.getUser().userClaims.nome.split(" ");
      if (arrNome.length <= 1) {
        setSiglaOla(arrNome[0].charAt(0) + arrNome[0].charAt(1));
      } else if (arrNome.length > 1) {
        setSiglaOla(arrNome[0].charAt(0) + arrNome[1].charAt(0));
      }
    }
  }, [userToken]);

  const pathname = () => {
    return window.location.pathname;
  };
  useEffect(() => {
    setFechaMenu(false);
  }, [window.location.pathname]);
  
  return (
    <Fragment>
      <div
        className={
          Storage.getUser() !== null
            ? "menuItens d-flex align-items-center menuDesktopLogado"
            : "menuItens d-flex align-items-center menuDesktopDeslogado"
        }
      >
        <ul>
          <li className={`${pathname() === "/" ? "active" : ""}`}>
            <Link to="/"> Início </Link>
          </li>

          <li
            className={`${pathname().includes("regulamento") ? "active" : ""}`}
          >
            <Link to="/regulamento"> Regulamento </Link>
          </li>

          <li className={`${pathname().includes("faq") ? "active" : ""}`}>
            <Link to="/faq"> Faq </Link>
          </li>

          <li
            className={`${pathname().includes("fale-conosco") ? "active" : ""}`}>
            <Link to="/fale-conosco"> Fale Conosco </Link>
          </li>

          {userToken && (
              <li
                className={`${pathname() === "/logout" ? "active" : ""}`}
                onClick={() => sairLogado()}
              >
                <Link to="/logout"> Sair </Link>
              </li>
            )}

        </ul>

        <ul>
          {!userToken && (
            <li>
              <a href="https://www.chevrolet.com.br/servicos/agendamento-online?evar25=clinicadeservicos"
                target="_blank"
                className="btn btn-outline-primary dark py-2 px-4"
                rel="noopener noreferrer">
                  FAÇA SEU AGENDAMENTO
              </a>
            </li>
          )}

          {userToken && (
            <div className="d-flex">
            
              <li className={`${pathname().includes("cadastro") ? "active" : ""}`}>
                <Link to="/cadastro"> Meus dados </Link>
              </li>
          
              <li
                className={`${pathname() === "/" ? "active" : ""}`}
                onClick={() => sairLogado()}
              >
                <Link to="/logout"> Sair </Link>
              </li>

            </div>
          )}

        </ul>

      </div>

      <div
        className={
          Storage.getUser() !== null
            ? "menuMobileExternoLogado"
            : "menuMobileExternoDeslogado"
        }
      >
        <Menu
          right
          isOpen={fechaMenu}
          onClose={() => {
            setFechaMenu(!fechaMenu);
          }}
          onOpen={() => {
            setFechaMenu(!fechaMenu);
          }}
        >
          
          <div className="d-flex justify-content-center w-100">
            <img className="logoCompany" src={logoCompany} alt="logo" />
          </div>

          <div className="menuItens d-flex flex-column menuMobile left">

            <ul>
              <li className={`${pathname() === "/" ? "active" : ""}`}>
                <Link to="/"> Início </Link>
              </li>

              <li
                className={`${
                  pathname().includes("regulamento") ? "active" : ""
                }`}
              >
                <Link to="/regulamento"> Regulamento </Link>
              </li>

              <li className={`${pathname().includes("faq") ? "active" : ""}`}>
                <Link to="/faq"> Faq </Link>
              </li>

              <li
                className={`${pathname().includes("fale-conosco")  ? "active" : ""}`}>
                <Link to="/fale-conosco"> Fale Conosco </Link>
              </li>
              
            </ul>

            <ul>
              {!userToken && (
                <li>
                  <a href="https://www.chevrolet.com.br/servicos/agendamento-online?evar25=clinicadeservicos"
                    target="_blank"
                    className="btn btn-outline-primary dark py-2 px-4"
                    rel="noopener noreferrer">
                      FAÇA SEU AGENDAMENTO
                  </a>
                </li>
              )}

              {userToken && (
                <li className={`${pathname() === "/cadastro" ? "active" : ""}`}>
                  <Link to="/cadastro"> Meus dados </Link>
                </li>
              )}

            </ul>

          </div>
        </Menu>
      </div>
    </Fragment>
  );
};

export default MenuNav;
