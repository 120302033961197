import React, { useEffect, useState } from "react";
import "./valecombustivel.scss";
import Mask from "../../services/mask";
import { trackPromise } from "react-promise-tracker";
import ApiServices from "../../services/apiServices";
import { TextField, InputAdornment, makeStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

/////////////////////////////
// redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  showMsgS,
  showMsgW,
  showMsgE,
} from "../../store/actions/snackbarActions.js";
import IconButton from "@material-ui/core/IconButton";
import BannerInicial from "../../assets/img/banners/banner_home.jpg";
import BannerInicialFooter from "../../assets/img/banners/banner_home_footer.jpg";
import Utils from "../../services/serviceUtils";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";

import { GoogleReCaptcha } from "react-google-recaptcha-v3";

const ModalCpf = ({ props }) => {
  const useStyles = makeStyles((theme) => ({
    icon: {
      fontSize: "4rem", // Ajuste o tamanho conforme necessário
    },
  }));

  const classes = useStyles();

  return (
    <DialogContent>
      {props && props.numeroSorteio && (
        <div className="row">
          <div className="col-md-12 col-sm-12 text-center">
            <h3>Olá {props.contactFirstName}!</h3>

            <p>Recebemos a confirmação do check-up da Concessionária :)</p>

            <div className="numero-da-sorte">
              <p className="big mt30">Seu número da sorte é:</p>
              <span>{props.numeroSorteio.numeroSorteio}</span>
            </div>

            <p>
              Você recebeu um e-mail com seu número da sorte no momento em que a
              concessionária confirmou o serviço.
            </p>
            <p>Caso não tenha localizado, verifique seu Spam.</p>

            <h3>Boa sorte!</h3>
          </div>
        </div>
      )}

      {props && !props.numeroSorteio && (
        <div className="row">
          <div className="col-md-12 col-sm-12 text-center">
            <h3>Olá {props.contactFirstName}!</h3>

            <p>O check-up ainda não foi confirmado pela Concessionária</p>

            <div className="numero-da-sorte-nao-encontrado">
              <span>
                <SentimentDissatisfiedIcon className={classes.icon} />
              </span>
            </div>

            <p>
              Caso já tenha realizado o check-up, entre em contato com a
              concessionária e solicite a confirmação.
            </p>
            <p>
              O número da sorte é gerado no momento em que a concessionária
              confirma a realização do check-up.
            </p>
            <p>
              Se encontrar dificuldades, utilize o Fale Conosco que estaremos
              prontos para te ajudar!
            </p>
          </div>
        </div>
      )}

      {!props && (
        <div className="row">
          <div className="col-md-12 col-sm-12 text-center">
            <h3>Olá!</h3>

            <p>Não localizamos seu CPF.</p>

            <div className="numero-da-sorte-nao-encontrado">
              <span>
                <SentimentDissatisfiedIcon className={classes.icon} />
              </span>
            </div>

            <p>Não localizamos seu CPF em nossa base.</p>
            <p>Por favor, tente novamente.</p>
          </div>
        </div>
      )}
    </DialogContent>
  );
};

function ValeCombustivel(props) {
  const useStyles = makeStyles((theme) => ({
    inputRoot: {
      "& .MuiOutlinedInput-root": {
        borderRadius: 0,
        "&.Mui-focused": {
          borderColor: "#E6AC39",
          borderWidth: 2,
          "& fieldset": {
            borderColor: "#E6AC39",
          },
        },
      },
    },
  }));

  const classes = useStyles();

  const [cpf, setCpf] = React.useState("");
  const [cpfData, setCpfData] = React.useState({});
  const [sorteioModal, setSorteioModal] = React.useState(true);
  const [open, setOpen] = React.useState(false);

  const modalOpenCpf = () => {
    setOpen(true);
  };
  const modalCloseCpf = () => {
    setOpen(false);
  };

  const changeCPF = (event) => {
    setCpf(Mask.cpf(event.target.value));
  };

  const sendCpf = () => {
    if (Utils.validaCPF(cpf)) {
      trackPromise(
        ApiServices.apiGet(`agendamento/consultar-cpf/${Mask.unMask(cpf)}`)
          .then((res) => {
            if (res.success) {
              console.log("res", res);
              setCpfData(res.data);
              modalOpenCpf();
            } else {
              props.showMsgW(res.errors);
            }
          })
          .catch((res) => {
            props.showMsgW(`Erro ao buscar cpf`);
          })
      );
    } else {
      props.showMsgW(`CPF inválido`);
    }
  };

  useEffect(() => {}, []);

  function handleVerify(valor) {
    props.handleVerify(valor);
  }

  return (
    <>
      <Dialog
        open={open}
        maxWidth={"md"}
        onClose={modalCloseCpf}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <IconButton aria-label="close" onClick={modalCloseCpf}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <ModalCpf props={cpfData} />
      </Dialog>

      <Dialog
        open={sorteioModal}
        maxWidth={"md"}
        onClose={() => {
          setSorteioModal(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <IconButton
            aria-label="close"
            onClick={() => {
              setSorteioModal(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h1>
            <b>SORTEIO FINAL</b>
          </h1>
          <br></br>
          <h3>CPF GANHADOR: 082.XXX.XXX-07 (Manoel)</h3>
        </div>
      </Dialog>

      <GoogleReCaptcha onVerify={handleVerify} />

      <div className="bgMainFeature">
        <img
          src={BannerInicial}
          alt="banner"
          className="d-none d-md-block responsive mAuto"
        />
        <img
          src={BannerInicial}
          alt="banner"
          className="d-md-none d-block responsive mAuto"
        />
      </div>

      <div className="container contentDefaultInicio">
        <div className="etapas row mt-5">
          <div className="col-md-3 col-sm-12 mb-5">
            <div className="etapa">
              <h2>01</h2>
              <h4>Agendamento</h4>
              <p>
                Realize o agendamento do check up gratuito ou de qualquer
                serviço
              </p>
            </div>
          </div>

          <div className="col-md-3 col-sm-12 mb-5">
            <div className="etapa">
              <h2>02</h2>
              <h4>Execução</h4>
              <p>
                Vá na concessionária e realize o check up ou serviço agendado do
                dia 13 a 23 de junho
              </p>
            </div>
          </div>

          <div className="col-md-3 col-sm-12 mb-5">
            <div className="etapa">
              <h2>03</h2>
              <h4>Participe do Sorteio</h4>
              <p>
                Após a realização do check up ou serviço, aguarde e receba um
                número da sorte por e-mail
              </p>
            </div>
          </div>

          <div className="col-md-3 col-sm-12 mb-5">
            <div className="etapa">
              <h2>04</h2>
              <h4>Sorteio Final</h4>
              <p>Confira seu número e concorra ao Onix ZERO KM</p>
            </div>
          </div>
        </div>
      </div>

      <div className="row text-center ml-5 mr-5">
        <div className="col-md-12">
          <div>
            <h2>
              <i>NÚMERO DA SORTE</i>
            </h2>
            <h4>
              Após a realização do check up ou serviço, você ganhará um número
              da sorte para concorrer ao sorteio de um Onix 0km.
            </h4>
            <p>
              Digite o número do seu CPF no campo abaixo para saber se seu
              número já foi gerado:
            </p>
          </div>
          <div className="mt30" style={{ marginBottom: "30px" }}>
            <TextField
              variant="outlined"
              placeholder="Pesquisar..."
              classes={{ root: classes.inputRoot }}
              value={cpf}
              onChange={changeCPF}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={sendCpf}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <p className="mb-4">
            Sorteio será realizado em 24/07/2024 e resultados serão divulgados
            em 23/08/2024.
          </p>
        </div>
      </div>
      <div className="bgMainFeature">
        <img
          src={BannerInicialFooter}
          alt="banner"
          className="d-none d-md-block responsive mAuto"
        />
        <img
          src={BannerInicialFooter}
          alt="banner"
          className="d-md-none d-block responsive mAuto"
        />
      </div>
    </>
  );
}

const mapStateToProps = (store) => ({
  snackbarState: store.snackbarState,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ showMsgS, showMsgW, showMsgE }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ValeCombustivel);
