import React, { useEffect } from "react";

/////////////////////////////
// redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  showMsgS,
  showMsgW,
  showMsgE,
} from "../../store/actions/snackbarActions.js";


import "./faq.scss";
import Faqs from "../../components/Faqs/Faqs";

const Faq = (props) => {
  const pathname = () => {
    return window.location.pathname;
  };

  // function handleVerify(valor) {
  //   // console.log(valor);
  //   props.handleVerify(valor);
  // }

  const [faqlist, setFaqlist] = React.useState([]);

  useEffect(() => {
    // trackPromise(
    //   ApiServices.apiGet('faqs')
    //     .then(res => {
    //       if (res.success) {
    //         setFaqlist(res.data)
    //       } else {
    //         props.showMsgW(`Erro ao carregar Faqs`)
    //       }
    //       setLoad(true)
    //     }).catch(res => {
    //       setLoad(true)
    //       props.showMsgW(`Erro ao carregar Faqs`)
    //     })
    // )
    setFaqlist([
      {
        id: "3fab3154-df28-4bc7-9b00-e9d63fa9af76",
        Titulo: "O que é a promoção “CLÍNICA DE SERVIÇOS CHEVROLET”?",
        Texto:
          "A presente Promoção aprovada pela SPA/ME na modalidade Assemelhada a Sorteio tem como objetivo o aumento do fluxo nas Concessionárias Chevrolet para a realização de Checkup gratuito ou serviço, através da distribuição de números da sorte para participação no sorteio de 1 (um) carro 0km, para todos os clientes que realizarem o agendamento via site chevrolet, comparecerem e realizarem o check up de 30 itens gratuito, ou qualquer serviço com os demais requisitos dispostos nos regulamentos da Promoção. Serão elegíveis os checkups e serviços realizados do dia 13 de junho de 2024 até 23 de junho de 2024.",
        Ordem: 1,
        status: true,
        DataCadastro: "2024-06-07T15:46:34.12",
        tipoFaq: 1,
      },
      {
        id: "29ec574a-a5c5-4e1b-b6e6-bef12c763157",
        Titulo: "Qual a premiação da presente Campanha?",
        Texto:
          "No final da campanha, será realizado o Sorteio de um ONIX 0km (modelo 2024/2025 na cor Prata Shark) com todos os custos pagos pela Chevrolet (emplacamento, licenciamento, documentação, IPVA).",
        Ordem: 2,
        status: true,
        DataCadastro: "2024-06-07T15:46:34.12",
        tipoFaq: 1,
      },
      {
        id: "2a829a4b-cc5d-483d-a6c5-076019582fdd",
        Titulo: "Quem pode participar?",
        Texto:
          "Poderão participar desta Campanha pessoas físicas maiores de 18 (dezoito) anos, inscritas e com o cadastro ativo no Cadastro de Pessoas Físicas (CPF) do Ministério da Fazenda, que realizarem o agendamento e comparecerem para o check-up gratuito (“Check-up Gratuito”) ou qualquer serviço, disponibilizados nas Lojas Físicas (“Concessionárias”) da MANDATÁRIA, durante o Período de Participação.",
        Ordem: 3,
        status: true,
        DataCadastro: "2024-06-07T15:46:34.12",
        tipoFaq: 1,
      },
      {
        id: "48eff117-3c84-4de4-a314-517e7dc57e6e",
        Titulo: "Como faço para participar?",
        Texto:
          '<p>Para participar da Promoção o cliente elegível interessado deve:</p><ol><li>Acessar o Hotsite da Promoção durante o período de participação da Promoção;</li><li>Realizar seu agendamento, no Hotsite da MANDATÁRIA <a href="https://www.chevrolet.com.br/servicos/agendamento-online" target="_blank">https://www.chevrolet.com.br/servicos/agendamento-online</a>, com as seguintes informações: (i) Nome Completo; (ii) CPF/ME; (iii) E-mail; e (iv) Telefone celular;</li><li>Comparecer na Concessionária em que realizou o agendamento, no dia e hora agendados para realizar o seu check-up gratuito ou qualquer serviço.</li></ol><p>Passo a Passo para participar da Campanha</p><ol><li>Agende o seu checkup de 30 itens gratuitos ou qualquer serviço em uma concessionária Chevrolet pelo site <a href="https://www.chevrolet.com.br/servicos/agendamento-online" target="_blank">https://www.chevrolet.com.br/servicos/agendamento-online</a></li><li>Compareça na Concessionária em que realizou o agendamento, no dia e hora agendados para realizar o seu Check-up Gratuito ou qualquer serviço. <br /><small><b>OBS: Para participar da promoção é obrigatório o comparecimento a concessionária no horário agendado.</b></small></li></ol><ol start="3"><li>Após o comparecimento no horário agendado, a concessionária confirmará essa ação e seu NÚMERO DA SORTE será gerado automaticamente e enviado no e-mail cadastrado.</li><li>Você pode acompanhar o andamento do seu número da sorte nesse mesmo hotsite, na sessão home, inserindo o seu CPF.</li><li>Boa sorte!</li></ol>',
        Ordem: 4,
        status: true,
        DataCadastro: "2024-06-07T15:46:34.12",
        tipoFaq: 1,
      },
      {
        id: "d0d1dd0a-9c3b-4ff9-b239-0317d9c8e6f7",
        Titulo: "Quem não pode participar?",
        Texto:
          '<p>Não serão contemplados na Campanha, e consecutivamente nos sorteios:</p><ol type="a"><li>Pessoas Jurídicas;</li><li>Pessoas físicas que não atenderem aos requisitos exigidos neste Regulamento;</li><li>Os membros do corpo diretivo, conforme respectivos documentos societários, da MANDATÁRIA;</li><li>Todos os empregados e colaboradores da MANDATÁRIA que estejam envolvidos em qualquer aspecto desta Promoção;</li><li>Todos os empregados e colaboradores das concessionárias participantes;</li><li>Todos os empregados e colaboradores da assessoria jurídica Focaccia, Amaral e Lamonica Sociedade de Advogados;</li><li>Qualquer indivíduo que não goze de sua capacidade legal.</li></ol>',
        Ordem: 5,
        status: true,
        DataCadastro: "2024-06-07T15:46:34.12",
        tipoFaq: 1,
      },
      {
        id: "e8202049-ba44-43f3-aaa4-1c6b2e0e8d70",
        Titulo: "Onde ocorrerá a campanha? Qual a abrangência?",
        Texto:
          "Esta Promoção será realizada nas concessionárias Chevrolet em todo o Território Nacional Brasileiro.",
        Ordem: 6,
        status: true,
        DataCadastro: "2024-06-07T15:46:34.12",
        tipoFaq: 1,
      },
      {
        id: "d652dcfb-7e3e-440e-8604-38cda738fba8",
        Titulo: "Qual o período de participação?",
        Texto:
          "A presente Promoção terá seu período de participação das 12h de 10/06/2024 até às 18h de 23/06/2024. <br/>Poderão agendar a partir do dia 10/06/2024 para realizar o check up gratuito de 06h de 13/06/2024 até às 18h de 23/06/2024.",
        Ordem: 7,
        status: true,
        DataCadastro: "2024-06-07T15:46:34.12",
        tipoFaq: 1,
      },
      {
        id: "74abb51e-9ebe-405a-8cb4-6696a6a0cb3e",
        Titulo: "Onde serão divulgados os resultados",
        Texto:
          'O participante receberá o número da sorte por e-mail, e também poderá acompanhar o status do seu número da sorte no hotsite <a href="https://www.clinicadeservicoschevrolet.com.br" target="_blank">www.clinicadeservicoschevrolet.com.br</a>, inserindo o CPF. O resultado do sorteio será divulgado na página inicial, no final da campanha.',
        Ordem: 8,
        status: true,
        DataCadastro: "2024-06-07T15:46:34.12",
        tipoFaq: 1,
      },
      {
        id: "fb87e480-41ba-44a4-80b8-c20318590dab",
        Titulo: "Realizei o checkup, e agora?",
        Texto:
          'Após a realização do checkup, o participante deve aguardar até receber e-mail com o seu número da sorte, que pode levar em torno de 20 dias. É possível também consultar o status via hotsite <a href="https://www.clinicadeservicoschevrolet.com.br" target="_blank">www.clinicadeservicoschevrolet.com.br</a>. Caso tenha alguma dúvida, entre em contato via Fale Conosco.',
        Ordem: 9,
        status: true,
        DataCadastro: "2024-06-07T15:46:34.12",
        tipoFaq: 1,
      },
      {
        id: "d1d07a24-bfc2-43e6-8ee1-264a7d5e43a0",
        Titulo: "Fui contemplado, em quanto tempo vou receber meu prêmio?",
        Texto:
          "O Prêmio Final objeto do Sorteio, é pessoal e intransferível e será entregue em nome do Participante Contemplado, no endereço da revenda em que o Participante realizou os Serviços, em até 30 (trinta) dias, contados da data da apuração. Para recebimento, o contemplado deverá apresentar os documentos solicitados pela Mandatária, sendo que este será entregue desde que atendidos todos os requisitos apresentados nos Regulamentos, disponíveis no hotsite da Promoção.",
        Ordem: 10,
        status: true,
        DataCadastro: "2024-06-07T15:46:34.12",
        tipoFaq: 1,
      },
      {
        id: "e18a4796-c94d-4d8c-a067-4bbe56203778",
        Titulo: "E quando será o SORTEIO do Carro 0km da Promoção?",
        Texto:
          "A apuração do ganhador do sorteio que vai distribuir um ONIX 0km ocorrerá no final da Promoção, após todos os números terem sido gerados, no dia 23/08/2024, às 16h horas, com base na extração do resultado do Sorteio da Loteria Federal realizado no dia 24/07/2024. A divulgação do Participante contemplado, se fará por meio do Hotsite da Promoção, assim que finalizada a apuração, permanecendo tais informações disponíveis por, pelo menos, 10 (dez) dias, contados da data da divulgação.",
        Ordem: 11,
        status: true,
        DataCadastro: "2024-06-07T15:46:34.12",
        tipoFaq: 1,
      },
      {
        id: "d0cc0548-ef45-427d-a920-29dcceae9ae9",
        Titulo: "Como é gerado meu número da sorte para o sorteio?",
        Texto:
          'O número da sorte é gerado automaticamente no hotsite da campanha <a href="https://www.clinicadeservicoschevrolet.com.br" target="_blank">www.clinicadeservicoschevrolet.com.br</a> após o concessionário validar o comparecimento do participante no dia e horário agendado, esse processo pode demorar em torno de 20 dias. Fique atento nos seus e-mails.',
        Ordem: 12,
        status: true,
        DataCadastro: "2024-06-07T15:46:34.12",
        tipoFaq: 1,
      },
      {
        id: "737ccaba-a056-483e-8fcb-8b76208baeef",
        Titulo: "Ganhei o sorteio, e agora?",
        Texto:
          "A Chevrolet ou parceiro Chevrolet entrará em contato com o ganhador para validar a participação e solicitar as informações e documentos necessários para realizar a entrega da premiação àquele que fizer jus, conforme regra do Regulamento. Para o recebimento do prêmio o Contemplado, deverá enviar os seguintes documentos em até 4 (quatro) dias úteis após a solicitação, sob pena de desclassificação: (i) cópia legível do seu RG; (ii) cópia legível do CPF/ME; e (iii) cópia legível de seu comprovante de residência, com data de no máximo 180 (cento e oitenta) dias anteriores à data da divulgação dos resultados, em seu nome ou em nome de algum parente ou cônjuge, acompanhado de outros documentos que comprovem seu grau de parentesco ou relação, consoante as instruções que serão fornecidas por meio de correspondência eletrônica e/ou via telefone. Neste momento, o Contemplado deverá ainda, assinar a Carta Compromisso e o recibo de entrega de prêmio que constituirá prova de entrega do prêmio.",
        Ordem: 13,
        status: true,
        DataCadastro: "2024-06-07T15:46:34.12",
        tipoFaq: 1,
      },
      {
        id: "089c294c-4245-4c31-bcfd-a43175e9de2f",
        Titulo: "Onde posso fazer o acompanhamento da promoção?",
        Texto:
          'O acompanhamento da promoção deve ser feito no hotsite da campanha, disponível em <a href="https://www.clinicadeservicoschevrolet.com.br" target="_blank">www.clinicadeservicoschevrolet.com.br</a>.',
        Ordem: 14,
        status: true,
        DataCadastro: "2024-06-07T15:46:34.12",
        tipoFaq: 1,
      },
      {
        id: "0fdf8149-1431-4150-8646-2957d1e9f463",
        Titulo: "Posso ser desclassificado da promoção?",
        Texto:
          'Os Participantes poderão ser excluídos automaticamente da Campanha em caso de tentativa de fraude, falsidade ou qualquer ato de má fé comprovado, não preenchimento dos requisitos previamente determinados e/ou em decorrência de informações incompletas, incorretas ou equivocadas, de acordo com as regras previstas no Regulamento, disponível no hotsite da campanha <a href="https://www.clinicadeservicoschevrolet.com.br" target="_blank">www.clinicadeservicoschevrolet.com.br</a>.',
        Ordem: 15,
        status: true,
        DataCadastro: "2024-06-07T15:46:34.12",
        tipoFaq: 1,
      },
      {
        id: "10e73f8f-2bcd-4708-93e5-3fd30f2beb1d",
        Titulo: "Tenho algum custo para poder usufruir da minha premiação?",
        Texto:
          "Não. O prêmio será entregue prontos para uso, sem ônus aos Contemplados. O Prêmio não poderá ser distribuído ou convertido, total ou parcialmente em dinheiro de acordo com o art. 15, § 5° do Decreto n°70.951/72. Ademais, o Prêmio não poderá ser trocado, alterado, complementado ou substituído por outro Prêmio.",
        Ordem: 16,
        status: true,
        DataCadastro: "2024-06-07T15:46:34.12",
        tipoFaq: 1,
      },
      {
        id: "2a98a3b5-ca25-4c68-ac50-0c17d3583cc1",
        Titulo: "Em caso de dúvidas, com quem posso entrar em contato?",
        Texto:
          "Para qualquer tipo de dúvidas, ou auxílio, entre em contato com o SAC Chevrolet através do nosso WhatsApp 11 99882-8157, de segunda a sexta das 9h às 18h. É possível entrar em contato pelo Fale Conosco no hotsite da Promoção, ou com o SAC Chevrolet através do Atendimento telefônico 0800 702 4200, de segunda a sexta das 08h às 20h e aos sábados das 08h às 14h, para que o problema seja solucionado. A resposta visando auxiliar o Participante será realizada em até 7 (sete) dias úteis, contados da data de contato com o SAC.",
        Ordem: 17,
        status: true,
        DataCadastro: "2024-06-07T15:46:34.12",
        tipoFaq: 1,
      },
    ]);
  }, []);

  const [load, setLoad] = React.useState(false);

  return (
    <>

      <div className="container contentDefault">
        <div className="row">
          <div className="col-12 text-center">
            <h1> PERTUNTAS FREQUENTES </h1>
            <h4> Tire suas dúvidas sobre a campanha </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt30">

            {pathname() === "/faq" && <Faqs faqs={faqlist} load={load} />}

          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (store) => ({
  snackbarState: store.snackbarState,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ showMsgS, showMsgW, showMsgE }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
