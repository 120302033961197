import React from "react";
import RegulamentoConteundo from "../../components/Docs/Texto/RegulamentoConteudo";

function Politica(props) {

  return (

    <div className="container contentDefault">
      <h1 className="col-12 text-center regulamentoPage">Regulamento</h1>

      <div className="row">
        <div className="col-12">
          <RegulamentoConteundo />
        </div>
      </div>
    </div>
  );
}

export default Politica;
