 
import globals from "../globals";
import Storage from "./storage";

function getToken() {
  let userCredencial = Storage.get("DEVP_USER_INFOS");
  return userCredencial.accessToken;
}
function getRoletaToken() {
  let userCredencial = Storage.get("DEVP_USER_INFOS_ROLETA");
  return userCredencial.token;
}

const ApiServices = {
  apiLogin: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${globals.REACT_APP_API_GM}${parameter}`, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(obj),
      }).then((res) => res.json());
    } else {
    }
  },

  apiGetWithToken: (parameter = undefined, token = undefined) => {
    if (parameter && token) {
      return fetch(`${globals.REACT_APP_API_GM}${parameter}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => res.json());
    } else {
    }
  },
  apiRoletaGetWithToken: (parameter = undefined) => {
    if (parameter) {
      return fetch(`${globals.REACT_APP_API_ROLETA}${parameter}`, {
        method: "GET",
        headers: {
          token: `${getRoletaToken()}`,
        },
      }).then((res) => res.json());
    }
  },

  apiRoletaGet: (parameter = undefined) => {
    if (parameter) {
      var captcha = Storage.get("captcha");
      if (captcha == undefined || captcha == "") {
        //alert('sem captcha');
        return fetch(`${globals.REACT_APP_API_ROLETA}${parameter}`, {
          method: "GET",
          headers: {
              'Authorization': `Bearer ${getToken()}`
          }
        }).then((res) => res.json());
      } else {
        return fetch(`${globals.REACT_APP_API_ROLETA}${parameter}`, {
          method: "GET",
          headers: {
            captcha: captcha, //,
            //'key':RECAPTCHAKEY
          },
        })
          .then((res) => {
            //alert(res.status);
            return res.json();
          }) //.json()
          .catch((error) => {
            //alert('Entrada no catch-X');
            //console.log(error.status);
            console.log(error);
            throw "Falha na consulta de dado- favor tentar novamente";
          });
        //console.log(result);
        //return result;
      }
    } else {
    }
  },
  apiRoletaPost: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      var captcha = Storage.get("captcha");
      if (captcha == undefined || captcha == "") {
        return fetch(`${globals.REACT_APP_API_ROLETA}${parameter}`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            //'Authorization': `Bearer ${getToken()}`
          },
          body: JSON.stringify(obj),
        }).then((res) => res.json());
      } else {
        return fetch(`${globals.REACT_APP_API_ROLETA}${parameter}`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            captcha: captcha, //,
            //'key':RECAPTCHAKEY
          },
          body: JSON.stringify(obj),
        }).then((res) => res.json());
      }
    } else {
    }
  },

  apiRoletaPostWithToken: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      var captcha = Storage.get("captcha");

      return fetch(`${globals.REACT_APP_API_ROLETA}${parameter}`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          captcha: captcha,
          token: `${getRoletaToken()}`,
          //'key':RECAPTCHAKEY
        },
        body: JSON.stringify(obj),
      }).then((res) => res.json());
    }
  },

  apiGet: (parameter = undefined) => {
    if (parameter) {
      var captcha = Storage.get("captcha");
      if (captcha == undefined || captcha == "") {
        //alert('sem captcha');
        return fetch(`${globals.REACT_APP_API_GM}${parameter}`, {
          method: "GET",
        }).then((res) => res.json());
      } else {
        return fetch(`${globals.REACT_APP_API_GM}${parameter}`, {
          method: "GET",
          // headers: {
          //   captcha: captcha, //,
          //   //'key':RECAPTCHAKEY
          // },
          headers: {
            captcha: captcha,
            "content-type": "application/json"
          },
        })
          .then((res) => {
            //alert(res.status);
            return res.json();
          }) //.json()
          .catch((error) => {
            //alert('Entrada no catch-X');
            //console.log(error.status);
            console.log(error);
            throw "Falha na consulta de dado- favor tentar novamente";
          });
        //console.log(result);
        //return result;
      }
    } else {
    }
  },
  apiGetCidade: (parameter = undefined) => {
    if (parameter) {
      var captcha = Storage.get("captcha");
      if (captcha == undefined || captcha == "") {
        //alert('sem captcha');
        return fetch(`${globals.REACT_APP_API_GM}${parameter}`, {
          method: "GET",
        }).then((res) => res.json());
      } else {
        return fetch(`${globals.REACT_APP_API_GM}${parameter}`, {
          method: "GET",
          // headers: {
          //   captcha: captcha, //,
          //   //'key':RECAPTCHAKEY
          // },
          headers: {
            captcha: captcha,
        
          },
        })
          .then((res) => {
            //alert(res.status);
            return res.json();
          }) //.json()
          .catch((error) => {
            //alert('Entrada no catch-X');
            //console.log(error.status);
            console.log(error);
            throw "Falha na consulta de dado- favor tentar novamente";
          });
        //console.log(result);
        //return result;
      }
    } else {
    }
  },

  apiPost: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      var captcha = Storage.get("captcha");
      if (captcha == undefined || captcha == "") {
        return fetch(`${globals.REACT_APP_API_GM}${parameter}`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            // 'Authorization': `Bearer ${getToken()}`
          },
          body: JSON.stringify(obj),
        }).then((res) => res.json());
      } else {
        return fetch(`${globals.REACT_APP_API_GM}${parameter}`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            captcha: captcha, //,
            //'key':RECAPTCHAKEY
          },
          body: JSON.stringify(obj),
        }).then((res) => res.json());
      }
    } else {
    }
  },

  apiPostWithToken: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      var captcha = Storage.get("captcha");
      if (captcha == undefined || captcha == "") {
        return fetch(`${globals.REACT_APP_API_GM}${parameter}`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(obj),
        }).then((res) => res.json());
      } else {
        return fetch(`${globals.REACT_APP_API_GM}${parameter}`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            captcha: captcha,
            Authorization: `Bearer ${getToken()}`,
            //'key':RECAPTCHAKEY
          },
          body: JSON.stringify(obj),
        }).then((res) => res.json());
      }
    } else {
    }
  },

  apiPostWithTokenNotaFiscal: (
    parameter = undefined,
    page = 1,
    per_page = 5,
    pesquisa = ""
  ) => {
    if (parameter) {
      var captcha = Storage.get("captcha");
      return fetch(`${globals.REACT_APP_API_GM}${parameter}`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          captcha: captcha,
          Authorization: `Bearer ${getToken()}`,
          //'key':RECAPTCHAKEY
        },
        body: JSON.stringify({
          page: page,
          per_page: per_page,
          pesquisa: pesquisa,
        }),
      }).then((res) => res.json());
    }
  },

  apiUpload: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${globals.REACT_APP_API_GM}${parameter}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getToken()}`,
          Accept: "multipart/form-data",
        },
        body: obj,
      }).then((res) => res.json());
    } else {
    }
  },

  apiPatch: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${globals.REACT_APP_API_GM}${parameter}`, {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(obj),
      }).then((res) => res.json());
    } else {
    }
  },

  apiPut: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${globals.REACT_APP_API_GM}${parameter}`, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(obj),
      }).then((res) => res);
      //.then(res => res.json());
    } else {
    }
  },

  apiPutReturnJson: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${globals.REACT_APP_API_GM}${parameter}`, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(obj),
      }).then((res) => res.json());
    } else {
    }
  },

  apiDel: (parameter = undefined) => {
    if (parameter) {
      return fetch(`${globals.REACT_APP_API_GM}${parameter}`, {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      }).then((res) => res);
    } else {
    }
  },

  apiPostWithTokenPremiacao: (
    parameter = undefined,
    page = 1,
    per_page = 5,
    pesquisa = ""
  ) => {
    if (parameter) {
      var captcha = Storage.get("captcha");
      return fetch(`${globals.REACT_APP_API_GM}${parameter}`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          captcha: captcha,
          Authorization: `Bearer ${getToken()}`,
          //'key':RECAPTCHAKEY
        },
        body: JSON.stringify({
          page: page,
          per_page: per_page,
          pesquisa: pesquisa,
        }),
      }).then((res) => res.json());
    }
  },

  apiGetWithIdAndToken: (parameter = undefined, id = undefined) => {
    if (parameter) {
      return fetch(`${globals.REACT_APP_API_GM}${parameter}/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }).then((res) => res.json());
    } else {
    }
  },
};

export default ApiServices;
