import React, { useState } from "react";
import "./cadastro.scss";
import Checkbox from "@material-ui/core/Checkbox";

import Mask from "../../services/mask.js";
import Utils from "../../services/serviceUtils.js";
import { useEffect } from "react";
import ApiServices from "../../services/apiServices.js";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  showMsgS,
  showMsgW,
  showMsgE,
} from "../../store/actions/snackbarActions.js";
import Storage from "../../services/storage.js";
import { Redirect } from "react-router-dom";

function Cadastro(props) {
  const [liberarBtSalvar, setLiberarBtSalvar] = useState(false);
  const [Motivo, setMotivo] = useState([]);
  const [usuarioCadastrado, setUsuarioCadastrado] = useState(false);
  const [alertaCampo, setAlertaCampo] = useState({
    cpf: {
      status: "d-none",
      alerta: "CPF invalido.",
    },
    email: {
      status: "d-none",
      alerta: "E-mail invalido.",
    },
    celular: {
      status: "d-none",
      alerta: "Telefone inválido.",
    },
    mensagem: {
      status: "d-none",
      alerta: "A mensagem deve conter no mínimo 10 caracteres.",
    },
    aceite: {
      status: "d-none",
      alerta: "Você deve concordar com as políticas de privacidade.",
    },
    nome: {
      status: "d-none",
      alerta: "O campo nome é obrigatório.",
    },
    motivo: {
      status: "d-none",
      alerta: "Você deve selecionar um motivo.",
    }
  });

  function msg(tipo, text) {
    //S W E
    if (tipo === "s") props.showMsgS(text);
    if (tipo === "w") props.showMsgW(text);
    if (tipo === "e") props.showMsgE(text);
  }

  const [chamadoNovo, setchamadoNovo] = useState({
    nome: "",
    cpf: "",
    email: "",
    phoneNumber: "",
    mensagem: "",
    motivoId: "",
    aceite: false
  });

  function fnBtSalvar() {
    
    const initNovo = {
      solicitanteNome: chamadoNovo.nome,
      solicitanteCpf: Mask.unMask(chamadoNovo.cpf),
      solicitanteEmail: chamadoNovo.email,
      solicitanteTelefone: Mask.unMask(chamadoNovo.phoneNumber),
      motivoId: chamadoNovo.motivoId,
      mensagem: chamadoNovo.mensagem,
    };

    function postFetch() {

      if (Storage.getUser() == null) {
        ApiServices.apiPost("chamados/adicionar", initNovo).then((data) => {
          if (data.success === true) {
            msg("s", data.data);
            setUsuarioCadastrado(data.success);
            setLiberarBtSalvar(false);
          } else {
            msg("w", data.errors[0]);
            setLiberarBtSalvar(false);
          }
        }, function() {
          setLiberarBtSalvar(false);
        });
      }
      
    }

    var ok = true;

    if (chamadoNovo.nome.length < 10) {
      setAlertaCampo((prev) => ({
        ...prev,
        nome: { status: "text-danger", alerta: "O campo nome deve conter no mínimo 10 caracteres." },
      }));
      ok = false;
    }

    if (Mask.unMask(chamadoNovo.phoneNumber).length < 10) {
      setAlertaCampo((prev) => ({
        ...prev,
        celular: { status: "text-danger", alerta: "Telefone inválido" },
      }));
      ok = false;
    }

    if (!chamadoNovo.aceite) {
      setAlertaCampo((prev) => ({
        ...prev,
        aceite: { status: "text-danger", alerta: "Você deve concordar com as políticas de privacidade." },
      }));
      ok = false;
    }

    if (chamadoNovo.motivoId.length < 10) {
      setAlertaCampo((prev) => ({
        ...prev,
        motivo: { status: "text-danger", alerta: "Você deve selecionar um motivo." },
      }));
      ok = false;
    }

    if (!Utils.validaCPF(chamadoNovo.cpf)) {
      setAlertaCampo((prev) => ({
        ...prev,
        cpf: { status: "text-danger", alerta: "CPF inválido" },
      }));
      ok = false;
    }

    if (!chamadoNovo.email.includes("@")) {
      setAlertaCampo((prev) => ({
        ...prev,
        email: { status: "text-danger", alerta: "E-mail inválido" },
      }));
      ok = false;
    }

    if (chamadoNovo.mensagem.length < 10) {
      setAlertaCampo((prev) => ({
        ...prev,
        mensagem: { status: "text-danger", alerta: "A mensagem deve conter no mínimo 10 caracteres." },
      }));
      ok = false;
    }

    if(ok) {
      postFetch();
    } else {
      setLiberarBtSalvar(false);
    }

  }

  useEffect(() => {
    ApiServices.apiGet("chamados/motivo/listar").then((data) => {
      setMotivo(data.data);
    });
  }, []);

  return (
    <div className="container">
      <div className="row">

        <div className="row justify-content-center">
          <div className="col-11">
            <br />
            <div className="text-center mb-5">
              <h1>Fale Conosco</h1>
              <br />

              <h4>Mande sua dúvida pra gente!</h4>

              <h6>Se você já leu as perguntas frequentes e ainda não encontrou uma solução, entre em contato e ficaremos satisfeitos em ajudar.</h6>
            </div>

            <div className="row">
              <div className="col-md-6 col-12 form-outline mb-3">
                <label className="form-label m0">Nome Completo *</label>
                <input
                  className="form-control"
                  type="text"
                  id="nome"
                  value={chamadoNovo.nome}
                  onChange={(e) => {
                    e.persist();
                    setchamadoNovo((prev) => ({
                      ...prev,
                      nome: e.target.value,
                    }));
                    setAlertaCampo((prev) => ({
                      ...prev,
                      nome: { status: "d-none", alerta: "" },
                    }));
                  }}
                />
                <p className={alertaCampo.nome.status}>
                  <em>{alertaCampo.nome.alerta}</em>
                </p>
              </div>

              <div className="col-md-6 col-12 form-outline mb-3">
                <label className="form-label m0">CPF *</label>

                <input
                  className="form-control"
                  type="text"
                  id="cpf"
                  placeholder="000.000.000-00"
                  value={Mask.cpf(chamadoNovo.cpf)}
                  onChange={(e) => {
                    alertaCampo.cpf.status = "d-none";
                    e.persist();
                    setchamadoNovo((prev) => ({
                      ...prev,
                      cpf: Mask.cpf(e.target.value),
                    }));
                    setAlertaCampo((prev) => ({
                      ...prev,
                      cpf: { status: "d-none", alerta: "" },
                    }));
                  }}
                />

                <p className={alertaCampo.cpf.status}>
                  <em>{alertaCampo.cpf.alerta}</em>
                </p>
              </div>

              <div className="col-md-6 col-12 form-outline mb-3">
                <label className="form-label m0">E-mail *</label>
                <input
                  className="form-control"
                  type="text"
                  id="email"
                  value={chamadoNovo.email}
                  onChange={(e) => {
                    alertaCampo.email.status = "d-none";
                    e.persist();
                    setchamadoNovo((prev) => ({
                      ...prev,
                      email: e.target.value,
                    }));
                    setAlertaCampo((prev) => ({
                      ...prev,
                      email: { status: "d-none", alerta: "" },
                    }));
                  }}
                />
                <p className={alertaCampo.email.status}>
                  <em>{alertaCampo.email.alerta}</em>
                </p>
              </div>

              <div className="col-md-6 col-12 form-outline mb-3">
                <label className="form-label m0">Telefone *</label>
                <input
                  className="form-control"
                  type="text"
                  id="phoneNumber"
                  placeholder="(00) 00000-0000"
                  value={Mask.cel(chamadoNovo.phoneNumber)}
                  onChange={(e) => {
                    alertaCampo.celular.status = "d-none";
                    e.persist();
                    setchamadoNovo((prev) => ({
                      ...prev,
                      phoneNumber: Mask.cel(e.target.value),
                    }));
                    setAlertaCampo((prev) => ({
                      ...prev,
                      celular: { status: "d-none", alerta: "" },
                    }));
                  }}
                />
                <p className={alertaCampo.celular.status}>
                  <em>{alertaCampo.celular.alerta}</em>
                </p>
              </div>

              <div className="col-md-6 col-sm-12 form-outline mb-3">
                <label className="form-label m0">Motivo *</label>

                <select
                  style={{ background: "#fff" }}
                  className="form-control col-12  "
                  onChange={(e) => {
                    e.persist();
                    setchamadoNovo((prev) => ({
                      ...prev,
                      motivoId: e.target.value,
                    }));
                    setAlertaCampo((prev) => ({
                      ...prev,
                      motivo: { status: "d-none", alerta: "" },
                    }));
                  }}
                >
                  <option value="" selected>Selecione um item...</option>
                  {Motivo.map((e, i) => (
                        <option key={i} value={e.id}>
                          {e.motivoContato}
                        </option>
                      ))}
                </select>
                <p className={alertaCampo.motivo.status}>
                  <em>{alertaCampo.motivo.alerta}</em>
                </p>
              </div>

              <div className="col-12 form-outline mb-3">
                <label className="form-label m0">Mensagem *</label>
                <textarea
                  className="form-control"
                  type="text"
                  id="mensagem"
                  value={chamadoNovo.mensagem}
                  onChange={(e) => {
                    e.persist();
                    setchamadoNovo((prev) => ({
                      ...prev,
                      mensagem: e.target.value,
                    }));
                    setAlertaCampo((prev) => ({
                      ...prev,
                      mensagem: { status: "d-none", alerta: "" },
                    }));
                  }}
                />
                <p className={alertaCampo.mensagem.status}>
                  <em>{alertaCampo.mensagem.alerta}</em>
                </p>
              </div>
            </div>

            <div className="row">
              <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="checkboxContainer">
                      <Checkbox
                        checked={
                          Storage.getUser() !== null
                            ? true
                            : chamadoNovo.aceite
                        }
                        onChange={(e) => {
                          e.persist();

                          setchamadoNovo((prev) => ({
                            ...prev,
                            aceite: e.target.checked,
                          }));
                          setAlertaCampo((prev) => ({
                            ...prev,
                            aceite: { status: "d-none", alerta: "" },
                          }));
                        }}
                      />

                      <p>

                      Autorizo a General Motors a coletar, armazenar e tratar os meus dados pessoais, compartilha-los com sua rede de concessionárias, Banco GM e parceiros bem como usá-los para comunicações por aplicativos de mensagens instantâneas (WhatsApp, Telegram, etc.), SMS, 
                      ligações e e-mails nos termos previstos na <a href="https://www.chevrolet.com.br/ajuda/politica-de-privacidade" target="_blank">Política de Privacidade</a>
                      
                      </p>
                    </div>
                    <p className={alertaCampo.aceite.status}>
                      <em>{alertaCampo.aceite.alerta}</em>
                    </p>
                  </div>
                </div>
              <div className="col-12 d-flex justify-content-center ">
                <button
                  className={`btn ${
                    liberarBtSalvar
                      ? "btn-outline-secondary"
                      : "btn-outline-primary"
                  } py-2 px-5 mt-4 mb-5`}
                  disabled={liberarBtSalvar}
                  onClick={() => {
                    setLiberarBtSalvar(true);
                    fnBtSalvar();
                  }}
                >
                  ENVIAR
                </button>
                {usuarioCadastrado === true && <Redirect to="/" />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (store) => ({
  snackbarState: store.snackbarState,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ showMsgS, showMsgW, showMsgE }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Cadastro);
