import React, { useEffect, useState } from 'react';
import Cadastro from '../../views/Cadastro/Cadastro';
import RegulamentoPage from '../../views/Regulamento/RegulamentoPage';
import Storage from "../../services/storage";
import ValeCombustivel from "../../views/ValeCombustivel/ValeCombustivel";
import Faq from '../../views/Faq/Faq';
import {
    GoogleReCaptchaProvider
  } from 'react-google-recaptcha-v3';

import globals from '../../globals'; 

function GeneralCaptcha(prop){

    const [direcionar,setDirecionar] = useState('');

    useEffect(() => {
       //var url = window.location.href;
       //url = url.replace(BASESITE,'');
       //alert(JSON.stringify(prop.location.pathname) );
       setDirecionar(prop.location.pathname);
    }, [prop]);
    
    function handleVerify(valor){
        Storage.set("captcha", valor);
    }

    return (
        <GoogleReCaptchaProvider reCaptchaKey={globals.REACT_APP_RECAPTCHA_KEY} >
            {
                (direcionar === '/')?
                    <ValeCombustivel handleVerify={handleVerify}  /> : null
            }
            {
                (direcionar==='/faq')?
                    <Faq handleVerify={handleVerify}  /> : null
            }
            {
                (direcionar==='/regulamento')?
                    <RegulamentoPage handleVerify={handleVerify}  /> : null
            }
            {
                (direcionar==='/fale-conosco')?
                    <Cadastro handleVerify={handleVerify}  /> : null
            }
        </GoogleReCaptchaProvider>
    )
}

export default GeneralCaptcha;